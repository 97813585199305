import { IItem } from '@rediredi/types'

export const useItemRedirect = (item?: IItem) => {
  if (!item) return

  const { event } = useEvent()
  const config = useRuntimeConfig()
  const globalStore = useGlobalStore()
  const currency = globalStore.storeCurrency

  window.open(`${config.public.API_URL}/inventory/storefront/${item.id}/redirect`, '_blank')

  event('generate_lead', {
    currency: currency || item.baseVariant.price?.currency,
    value: item.baseVariant.price?.amount,
  })
}
