import { IItem, IItemVariant, IItemVariantOptionMap } from '@rediredi/types'

export const stringToHTML = (string: string) => string.replace(/\n/gim, '<br />')

export const getVariantByOptions = (variants: IItemVariant[], options: IItemVariantOptionMap[]) => {
  if (!options.length) return

  return variants.find((variant) => {
    if (!variant.optionMapping) return false
    return variant.optionMapping.every((option, i) => {
      if (!options[i]) return false
      return option.name === options[i].name && option.value === options[i].value
    })
  })
}

export const getItemVariants = (item: IItem) => {
  const variants = []
  if (item?.baseVariant) variants.push(item?.baseVariant)
  variants.push(...(item?.additionalVariants || []))
  return variants.filter((variant) => !variant.archived && (variant.quantityTotal || 0) > 0)
}

export const getCoverVariantPictures = (item: IItem) => {
  if(item.options?.length) {
    const variants = getItemVariants(item)
    const coverVariant = variants.find((variant) => variant.position === 1)
  
    if (coverVariant?.pictures?.length) return coverVariant.pictures
  }
  if (item.pictures?.length) {
    return item.pictures
  }
  return []
}
