import { IItemVariantOption } from '@rediredi/types'
import { IStorefrontItem } from '~/types/item'

export const useItemStore = defineStore('item', {
  state: (): { currentItem?: IStorefrontItem } => ({
    currentItem: undefined,
  }),
  getters: {
    variants: (state) => (state.currentItem ? getItemVariants(state.currentItem) : []),

    inStockVariants: (state) =>
      (state.currentItem ? getItemVariants(state.currentItem) : []).filter(
        (variant) => Number(variant.quantityAvailable) > 0,
      ),

    options: (state) => {
      if (!state.currentItem?.options?.length) return []

      const variants = state.currentItem ? getItemVariants(state.currentItem) : []
      const options: IItemVariantOption[] = []

      state.currentItem.options.forEach((option) => {
        const values = option.values.filter((value) => {
          return !!variants.find((variant) => {
            if (!variant.optionMapping) return false

            return variant.optionMapping.some((optionMapping) => {
              return optionMapping.name === option.name && optionMapping.value === value.name
            })
          })
        })
        options.push({ ...option, values })
      })

      const coverVariant = variants.find((variant) => variant.position === 1) || null
      options.forEach((option, i) => {
        option.values.sort((a, b) => a.name.localeCompare(b.name))

        if (i === 0) {
          option.values.sort((a) => {
            return a.name === coverVariant?.optionMapping?.[0].value ? -1 : 1
          })
        }
        if (i === 1) {
          option.values.sort((a) => {
            return a.name === coverVariant?.optionMapping?.[1]?.value ? -1 : 1
          })
        }
      })

      return options
    },

    optionsInStock: (state) => {
      if (!state.currentItem?.options?.length) return []

      const variants = (state.currentItem ? getItemVariants(state.currentItem) : []).filter(
        (v) => (v.quantityAvailable || 0) > 0,
      )
      const options: IItemVariantOption[] = []

      state.currentItem.options.forEach((option) => {
        const values = option.values.filter((value) => {
          return !!variants.find((variant) => {
            if (!variant.optionMapping) return false

            return variant.optionMapping.some((optionMapping) => {
              return optionMapping.name === option.name && optionMapping.value === value.name
            })
          })
        })
        options.push({ ...option, values })
      })

      const coverVariant = variants.find((variant) => variant.position === 1) || null
      options.forEach((option, i) => {
        option.values.sort((a, b) => a.name.localeCompare(b.name))

        if (i === 0) {
          option.values.sort((a) => {
            return a.name === coverVariant?.optionMapping?.[0].value ? -1 : 1
          })
        }
        if (i === 1) {
          option.values.sort((a) => {
            return a.name === coverVariant?.optionMapping?.[1]?.value ? -1 : 1
          })
        }
      })

      return options
    },
  },
})
